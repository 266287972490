import React, { useReducer, useEffect } from 'react';
import Layout from '../components/IsolatedLayout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { ColorPalettes, Texter } from 'kinedu-react-components';
import Divider from '../components/Divider';
import { t } from '../language/config';
import TexterBasic from '../styles/themes/TexterBasic';
import GoogleButton from '../components/GoogleButton';
import MainButton from '../components/MainButton';
import { parse } from 'query-string';
import useAuth from '../hooks/useAuth';
import { WithTrans } from '../language/withTrans';
import { isSSR } from '../lib/utils';
import { windowGlobal } from '../config';

const { KineduPalette } = ColorPalettes;

const Title = styled.span`
  color: ${KineduPalette.oldkinedu};
  margin: 30px 0 10px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.p`
  color: ${KineduPalette.shadeMediumDark};
  margin: 10px 0 20px;
  font-size: 16px;
  text-align: center;
`;

const InputGroup = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0039;
  font-size: 0.7rem;
  padding: 10px 0px;
  margin: -20px 0 0;
  text-transform: initial;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_VALUE':
      return { ...state, [action.name]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  email: '',
  password: '',
};


// NOTE: Example URL
// https://staging-classrooms.kinedu.com/set-password?email=hector%2B234%40kinedu.com&reset_password_token=dV2KnNs2RcKmgy6nmo8R

const SetPassword = ({ location }) => {
  const [data, dispatch] = useReducer(reducer, initialState);
  const { resetPassword, errors, setErrors } = useAuth();
  const { email, reset_password_token: token, school_name: schoolName = t('MY_SCHOOL') } = parse(location.search);

  useEffect(() => {
    windowGlobal.localStorage.removeItem('auth_token');
  }, []);

  const handleResetPassword = () => {
    setErrors({
      ...errors,
      emailError: data.email === '' ? 'Email is required' : '',
      passError: data.password === '' ? 'Password is required' : '',
    });
    if (data.email === '' || data.password === '') return;
    resetPassword(token, data.password, data.password, 'set-password', schoolName);
  };

  const handleOnChange = (name, value) => {
    dispatch({ type: 'SET_INPUT_VALUE', name, value });
  };

  useEffect(() => {
    dispatch({ type: 'SET_INPUT_VALUE', name: 'email', value: email });
  }, [email]);

  useEffect(() => {
    if(typeof window !== "undefined"){
      window.location?.assign(window.location.href.replace(window.origin, `${window.origin}/app`))
   }
  }, [])


  return (
    <Layout>
      <SEO title="Set password" />
      <Title>{t('SET_PASS_VIEW.TITLE', { schoolName })}</Title>
      <Subtitle>{t('SET_PASS_VIEW.SUBTITLE')}</Subtitle>
      <GoogleButton altVersion>{t('SIGNUP_WITH_GOOGLE')}</GoogleButton>
      <Divider text={t('OR')} />
      <InputGroup>
        <Texter
          type="email"
          name="email"
          label={t('EMAIL')}
          theme={TexterBasic}
          value={email}
          disabled
        />
        <Texter
          type="password"
          name="password"
          label={t('PASSWORD')}
          theme={TexterBasic}
          value={data.password}
          onChange={(value) => handleOnChange('password', value)}
        />
        <ErrorMessage>{t(errors.generalError || errors.confirmPassError)}</ErrorMessage>
        <MainButton
          fill
          onClick={handleResetPassword}
        >
          {t('GET_STARTED')}
        </MainButton>
      </InputGroup>
    </Layout>
  );
};

export default WithTrans()(SetPassword);
